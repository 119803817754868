import {all} from "redux-saga/effects";
import organizationSaga from "./organizationSaga";
import availableClassesSaga from "./availableClassesSaga";
import reservationSaga from "./reservationSaga";
import authSaga from "./authSaga";
import userSaga from "./userSaga";
import paymentSaga from "./paymentSaga";

export default function* rootSaga() {
    yield all([
        organizationSaga(),
        availableClassesSaga(), 
        reservationSaga(),
        authSaga(),
        userSaga(),
        paymentSaga(),
    ]);
}