import actions from "../../store/types";
import {put, call, all, takeEvery} from "redux-saga/effects";
import { savePayment } from "../services/paymentAPI";


function* PAYMENT_SAVE(action) {
    try {
        yield put({
            type: actions.PAYMENT_LOADING,
            payload: true
        });
        const payment = yield call(savePayment(action.paymentDetails));
        if(payment.data.id) {
            yield put({
                type: actions.PAYMENT_SAVE_RESPONSE,
                payload: {paymentId: payment.data.id}
            })
        } else {
            yield put({
                type: actions.PAYMENT_SAVE_RESPONSE,
                payload: {error: "failed to save payment"}
            })
        }
    } catch(error) {
        yield put({
            type: actions.PAYMENT_SAVE_RESPONSE,
            payload: {error: `failed to save payment ${error.message}`}
        })
    } finally {
        yield put({
            type: actions.PAYMENT_LOADING,
            payload: false
        })
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.PAYMENT_SAVE, PAYMENT_SAVE),
    ])
}
