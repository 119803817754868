import actions from "../../store/types";
import {put, call, all, takeEvery, select} from "redux-saga/effects";
import { otpRequest, otpVerify, refreshTokens } from "../services/authAPI";
import { getRefreshToken } from "./commonSaga";

function* AUTH_OTP_REQUEST(action) {
    try {
        console.log('requesting otp ');
        console.log(action.payload);
        yield put({
            type: actions.AUTH_LOADING,
            payload: true
        });
        const response = yield call(otpRequest(action.payload));
        console.log(`response: ${response}`);
        yield put({
            type: actions.AUTH_OTP_REQUEST_SUCCESS,
            payload: response.data
        })
    } catch(error) {
        console.log(`error - ${error.message}`);
        yield put({
            type: actions.AUTH_OTP_REQUEST_SUCCESS,
            payload: {"error": error.message}
        })
    } finally {
        yield put({
            type: actions.AUTH_LOADING,
            payload: false
        })
    }
}

function *AUTH_REFRESH_TOKENS(action) {
    try {
        console.log('refreshing tokens ');
        const refreshToken = yield select(getRefreshToken);
        yield put({
            type: actions.AUTH_LOADING,
            payload: true
        });
        const response = yield call(refreshTokens({refreshToken: refreshToken}));
        console.log(`response: ${response}`);
        if(response.status == 200) {
            yield put({
                type: actions.AUTH_SET_TOKENS,
                payload: response.data
            })
            yield put({
                type: actions.USER_SET_LOGGED_IN,
                payload: true
            })
            yield put({
                type: actions.FETCH_USER_DETAILS,
                accessToken: response.data.accessToken
            })
        }
    } catch(error) {
        console.log(`error - ${error.message}`);
        yield put({
            type: actions.AUTH_RESET_TOKENS,
        })
        yield put({
            type: actions.USER_SET_LOGGED_IN,
            payload: false
        })
        yield put({
            type: actions.RESET_USER_DETAILS,
        })
    } finally {
        yield put({
            type: actions.AUTH_LOADING,
            payload: false
        })
    }
}
function* AUTH_OTP_VERIFY(action) {
    try {
        console.log('verifying otp ');
        console.log(action.payload);
        yield put({
            type: actions.AUTH_LOADING,
            payload: true
        });
        const response = yield call(otpVerify(action.payload));
        console.log(`response: ${response}`);
        yield put({
            type: actions.AUTH_OTP_VERIFY_SUCCESS,
            payload: response.data
        })
        if(response.status == 200) {
            yield put({
                type: actions.AUTH_SET_TOKENS,
                payload: response.data
            })
            yield put({
                type: actions.USER_SET_LOGGED_IN,
                payload: true
            })
            yield put({
                type: actions.FETCH_USER_DETAILS,
                accessToken: response.data.accessToken
            })
        }
    } catch(error) {
        console.log(`error - ${error.response.data.error}`);
        yield put({
            type: actions.AUTH_OTP_VERIFY_SUCCESS,
            payload: {"error": error.response.data.error}
        })
    } finally {
        yield put({
            type: actions.AUTH_LOADING,
            payload: false
        })
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.AUTH_OTP_REQUEST, AUTH_OTP_REQUEST),
        takeEvery(actions.AUTH_OTP_VERIFY, AUTH_OTP_VERIFY),
        takeEvery(actions.AUTH_REFRESH_TOKENS, AUTH_REFRESH_TOKENS),
    ])
}