import axios from "axios";
import { API_ENDPOINT } from "../../config";

const baseUrl = API_ENDPOINT;

export const getAvailableClasses = (orgId) => async () => {
    return axios({
        method: "get",
        url: `${baseUrl}/availableClasses?orgId=${orgId}`,
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export const fetchAvailableClass = (classId) => async () => {
    return axios({
        method: "get",
        url: `${baseUrl}/availableClasses/${classId}`,
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}


export const updateAvailableClasses = (payload) => async () => {
    return axios({
        method: "post",
        url: `${baseUrl}/availableClasses`,
        headers: {
            "Content-Type": "application/json"
        },
        data: payload
    })
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err));
}