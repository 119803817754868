import actions from "../../store/types";
import {put, call, all, takeEvery, select} from "redux-saga/effects";
import { getReservation, saveReservation, listReservations, listOrders } from "../services/reservationAPI";
import { getAccessToken } from "./commonSaga";

function* LIST_RESERVATIONS(action) {
    try {
        yield put({
            type: actions.RESERVATION_LOADING,
            payload: true
        });
        const reservations = yield call(listReservations(action.queryParams));
        console.log(`reservation id - ${reservations}`)
        yield put({
            type: actions.LOAD_RESERVATION_LIST,
            currentReservationList: reservations
        })
    } catch(error) {
        yield put({
            type: actions.LOAD_RESERVATION_LIST,
            currentReservationList: {"error": error.message}
        })
    } finally {
        yield put({
            type: actions.RESERVATION_LOADING,
            payload: false
        })
    }
}
function* LIST_ORDERS(action) {
    try {
        const accessToken = yield select(getAccessToken);
        yield put({
            type: actions.RESERVATION_LOADING,
            payload: true
        });
        const reservations = yield call(listOrders(action.queryParams, accessToken));
        console.log(`reservation id - ${reservations}`)
        yield put({
            type: actions.LOAD_RESERVATION_LIST,
            currentReservationList: reservations
        })
    } catch(error) {
        if(error.response.data.statusCode === 401 && error.response.data.message === "Authorization token expired") {
            yield put({
                type: actions.AUTH_REFRESH_TOKENS
            })
            yield put({
                type: actions.LIST_ORDERS,
            })
        } else {
            yield put({
                type: actions.LOAD_RESERVATION_LIST,
                currentReservationList: {"error": error.message}
            })
        }
    } finally {
        yield put({
            type: actions.RESERVATION_LOADING,
            payload: false
        })
    }
}
function* GET_RESERVATION(action) {
    try {
        yield put({
            type: actions.RESERVATION_LOADING,
            payload: true
        });
        const reservation = yield call(getReservation(action.reservationId));
        console.log(`reservation id - ${reservation}`)
        yield put({
            type: actions.LOAD_RESERVATION,
            currentReservation: reservation
        })
    } catch(error) {
        yield put({
            type: actions.LOAD_RESERVATION,
            currentReservation: {"error": error.message}
        })
    } finally {
        yield put({
            type: actions.RESERVATION_LOADING,
            payload: false
        })
    }
}
function* SAVE_RESERVATION(action) {
    try {
        yield put({
            type: actions.RESERVATION_LOADING,
            payload: true
        });
        const reservation = yield call(saveReservation(action.reservationDetails));
        if(reservation.data.id) {
            yield put({
                type: actions.GET_RESERVATION,
                reservationId: reservation.data.id 
            })
            yield put({
                type: actions.GET_AVAILABLE_CLASSES,
                orgId: action.reservationDetails.orgId
            })
        }
    } catch(error) {
        yield put({
            type: actions.LOAD_RESERVATION,
            currentReservation: {"error": error.message}
        })
    } finally {
        yield put({
            type: actions.RESERVATION_LOADING,
            payload: false
        })
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.SAVE_RESERVATION, SAVE_RESERVATION),
        takeEvery(actions.GET_RESERVATION, GET_RESERVATION),
        takeEvery(actions.LIST_RESERVATIONS, LIST_RESERVATIONS),
        takeEvery(actions.LIST_ORDERS, LIST_ORDERS),
    ])
}