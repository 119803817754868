import React, {Component} from 'react';
import {connect} from "react-redux";
import { styled } from '@mui/material/styles';
import {ThemeProvider} from "@mui/material/styles";
import {desiArtHubTheme} from '../../themes/desiarthub';
import MuiAppBar from '@mui/material/AppBar';
import { Box, CssBaseline, Toolbar, SwipeableDrawer, Divider, Typography, Menu, MenuItem, Button, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavBarLoggerIn from '../../components/NavBarLoggedIn';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { resetTokens } from '../../store/actions/authAction';

import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        match={{ location, navigate, params }}
      />
    );
  }
  return ComponentWithRouterProp;
}

const drawerWidth = 280;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

class NavDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            open: false,
            anchorEl: null,
        }
    }
  handleDrawerOpen = () => {
    this.setState({open:true})
  };

  handleDrawerClose = () => {
    this.setState({open:false})
  };
  handleMenu = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };
  handleClose = () => {
    this.setState({anchorEl: null});
  };
  handleProfileClick = () => {
    this.setState({anchorEl: null});
    this.props.match.navigate("/uprofile");
  }
  handleAccountClick = () => {
    this.setState({anchorEl: null});
    this.props.match.navigate("/account");
  }
  logout = () => {
    this.setState({anchorEl: null});
    this.props.resetTokens();
  }
  handleLogin = () => {
    this.props.match.navigate("/login");
  }
  render() {
    const { anchorEl } = this.state;
    return (
      <ThemeProvider theme={desiArtHubTheme}>
        <Box sx={{ flexGrow: 1, width: "100px" }}>
        <CssBaseline />
        <AppBar style={{backgroundColor: 'black'}} position="fixed" open={this.state.open}>
            <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(this.state.open && { display: 'none' }) }}
            >
                <MenuIcon />
            </IconButton>
            <Typography component="a" href="/" variant="h6" sx={{
              mr: 2,
              flexGrow: 1,
              color: 'inherit',
              textDecoration: 'none',
            }} >
                Desi Art Hub
            </Typography>
            {!this.props.loggedIn && (
              <Button style={{width: '150px'}} onClick={this.handleLogin}>Login</Button>
            )}
              {this.props.loggedIn && (
              <div>
                <Stack style={{display: "flex", flexDirection: "row", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
                <Typography style={{fontSize: "1.25rem"}}>Welcome {this.props.userDetails.firstName}</Typography>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleProfileClick}>Profile</MenuItem>
                  <MenuItem onClick={this.handleAccountClick}>My account</MenuItem>
                  <MenuItem onClick={this.logout}>Logout</MenuItem>
                </Menu>
                </Stack>
              </div>
            )}
            </Toolbar>
        </AppBar>
        <SwipeableDrawer
            sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
            },
            }}
            variant="temporary"
            anchor="left"
            open={this.state.open}
        >
            <DrawerHeader>
            <IconButton onClick={this.handleDrawerClose}>
                {desiArtHubTheme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            </DrawerHeader>
            <Divider />
            <NavBarLoggerIn></NavBarLoggerIn>
        </SwipeableDrawer>
        <Main open={this.state.open}>
            <DrawerHeader />
        </Main>
        </Box>
        </ThemeProvider>
    )};
}

const mapStateToProps = (state) => ({
    loggedIn: state.user.loggedIn,
    userDetails: state.user.userDetails,
})

export default withRouter(connect(mapStateToProps, {resetTokens})(NavDrawer));