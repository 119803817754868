import actions from "../../store/types";
import {put, call, all, takeEvery, select} from "redux-saga/effects";
import { fetchUserDetails, updateUserInfo } from "../services/userAPI";
import { getAccessToken } from "./commonSaga";

function* FETCH_USER_DETAILS(action) {
    try {
        const accessToken = yield select(getAccessToken);
        console.log('fetching user details ');
        yield put({
            type: actions.USER_LOADING,
            payload: true
        });
        const response = yield call(fetchUserDetails(accessToken));
        console.log(`response: ${response}`);
        yield put({
            type: actions.USER_DETAILS_RESPONSE,
            payload: response.data
        })
    } catch(error) {
        console.log(`error - ${error.message}`);
        yield put({
            type: actions.USER_DETAILS_RESPONSE,
            payload: {"error": error.message}
        })
    } finally {
        yield put({
            type: actions.USER_LOADING,
            payload: false
        })
    }
}
function* UPDATE_USER_DETAILS(action) {
    try {
        const accessToken = yield select(getAccessToken);
        console.log('updating user details ');
        yield put({
            type: actions.USER_UPDATE_LOADING,
            payload: true
        });
        const response = yield call(updateUserInfo(accessToken, action.payload));
        console.log(`response: ${response}`);
        yield put({
            type: actions.USER_UPDATE_RESPONSE,
            payload: response.data
        })
        yield put({
            type: actions.FETCH_USER_DETAILS,
            accessToken: accessToken
        })
    } catch(error) {
        console.log(`error - ${error.message}`);
        yield put({
            type: actions.USER_UPDATE_RESPONSE,
            payload: {"error": error.message}
        })
    } finally {
        yield put({
            type: actions.USER_UPDATE_LOADING,
            payload: false
        })
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.FETCH_USER_DETAILS, FETCH_USER_DETAILS),
        takeEvery(actions.UPDATE_USER_DETAILS, UPDATE_USER_DETAILS)
    ])
}