import actions from '../types';

const initialState = {
    accessToken: null,
    refreshToken: null,
    email: null,
}

export default function authReducer(state = initialState, action) {
    switch  (action.type) {
        case actions.AUTH_LOADING:
            return {
                ...state, isAuthLoading: action.payload
            };
        case actions.AUTH_OTP_REQUEST_SUCCESS:
            return {
                ...state, otpRequestSuccess: action.payload
            }
        case actions.AUTH_RESET:
            return {
                ...state, otpRequestSuccess: {}, isAuthLoading: false
            }
        case actions.AUTH_OTP_VERIFY_SUCCESS:
            return {
                ...state, authLoginResponse: action.payload
            }
        case actions.AUTH_SET_TOKENS:
            return {
                ...state, 
                accessToken: action.payload.accessToken, 
                refreshToken: action.payload.refreshToken
            }
        case actions.AUTH_RESET_TOKENS:
            return {
                ...state, 
                accessToken: null, 
                refreshToken: null
            }
        default:
            return state;
    }

}