import actions from "../../store/types";
import {put, call, all, takeEvery} from "redux-saga/effects";

import { fetchAvailableClass, getAvailableClasses, updateAvailableClasses } from "../services/availableClassesAPI";

function* GET_AVAILABLE_CLASSES(action) {
    try {
        console.log(`GET_AVAILABLE_CLASSES: getting available classes for ${action.orgId}`)
        yield put({
            type: actions.AVAILABLE_CLASSES_LOADING,
            payload: true
        });
        const availableClasses = yield call(getAvailableClasses(action.orgId));
        yield put({
            type: actions.LOAD_AVAILABLE_CLASSES,
            availableClasses: availableClasses 
        })
    } catch(error) {
        console.log(error);
    } finally {
        yield put({
            type: actions.AVAILABLE_CLASSES_LOADING,
            payload: false
        })
    }
}

function* FETCH_AVAILABLE_CLASS(action) {
    try {
        yield put({
            type: actions.AVAILABLE_CLASSES_LOADING,
            payload: true
        })
        const currentAvailableClass = yield call(fetchAvailableClass(action.classId));
        console.log(`dispatching SET_CURRENT_AVAILABLE_CLASSES ${JSON.stringify(currentAvailableClass)}`)
        yield put({
            type: actions.SET_CURRENT_AVAILABLE_CLASSES,
            currentAvailableClass: currentAvailableClass
        })
    } catch(error) {
        console.log(error);
    } finally {
        yield put ({
            type: actions.AVAILABLE_CLASSES_LOADING,
            payload: false
        })
    }
}
function* UPDATE_AVAILABLE_CLASSES(action) {
    try {
        console.log('updating available Classes ');
        console.log(action.payload);
        yield put({
            type: actions.AVAILABLE_CLASSES_LOADING,
            payload: true
        });
        const response = yield call(updateAvailableClasses(action.payload));
        console.log(response);
        if(response.status == 200) {
            yield put({
                type: actions.UPDATED_AVAILABLE_SUCCESS,
                payload: true
            })
            yield put({
                type: actions.GET_AVAILABLE_CLASSES,
                orgId: action.payload.orgId 
            })
        } else {
            yield put({
                type: actions.UPDATED_AVAILABLE_SUCCESS,
                payload: false
            })
        }
    } catch(error) {
        console.log(error);
        yield put({
            type: actions.UPDATED_AVAILABLE_SUCCESS,
            payload: false
        })
        yield put({
            type: actions.LOAD_AVAILABLE_CLASSES,
            currentOrg: {"error": error.message}
        })
    } finally {
        yield put({
            type: actions.AVAILABLE_CLASSES_LOADING,
            payload: false
        })
    }
}
export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_AVAILABLE_CLASSES, GET_AVAILABLE_CLASSES),
        takeEvery(actions.UPDATE_AVAILABLE_CLASSES, UPDATE_AVAILABLE_CLASSES),
        takeEvery(actions.FETCH_AVAILABLE_CLASS, FETCH_AVAILABLE_CLASS),
    ])
}