export default {
    ORG_LOADING: "org/ORG_LOADING",
    LOAD_ORG: "org/LOAD_ORG",
    GET_ORG: "org/GET_ORG",
    GET_MY_ORG: "org/GET_MY_ORG",
    UPDATE_ORG: "org/UPDATE_ORG",
    UPDATED_ORG_SUCCESS: "org/UPDATED_ORG_SUCCESS",
    RESET_ORG: "org/RESET",
    AVAILABLE_CLASSES_LOADING: "org/AVAILABLE_CLASSES_LOADING",
    GET_AVAILABLE_CLASSES: "availableClasses/GET_AVAILABLE_CLASSES",
    FETCH_AVAILABLE_CLASS: "availableClasses/FETCH_AVAILABLE_CLASS",
    SET_CURRENT_AVAILABLE_CLASSES: "availableClasses/SET_CURRENT_AVAILABLE_CLASSES",
    LOAD_AVAILABLE_CLASSES: "availableClasses/LOAD_AVAILABLE_CLASSES",
    UPDATE_AVAILABLE_CLASSES: "org/UPDATE_AVAILABLE_CLASSES",
    UPDATED_AVAILABLE_SUCCESS: "org/UPDATED_AVAILABLE_SUCCESS",
    RESET_AVAILABLE_CLASSES: "availableClasses/RESET_AVAILABLE_CLASSES",
    
    SAVE_RESERVATION: "reservations/SAVE_RESERVATION",
    GET_RESERVATION: "reservations/GET_RESERVATION",
    LIST_RESERVATIONS: "reservations/LIST_RESERVATIONS",
    LIST_ORDERS: "reservations/LIST_ORDERS",
    LOAD_RESERVATION: "reservations/LOAD_RESERVATION",
    LOAD_RESERVATION_LIST: "reservations/LOAD_RESERVATION_LIST",
    RESERVATION_LOADING: "reservations/RESERVATION_LOADING",
    RESET_RESERVATION: "reservations/RESET_RESERVATION",


    AUTH_OTP_REQUEST: "auth/OTP_REQUEST",
    AUTH_OTP_VERIFY: "auth/OTP_VERIFY",
    AUTH_LOADING: "auth/AUTH_LOADING",
    AUTH_OTP_REQUEST_SUCCESS: "auth/AUTH_OTP_REQUEST_SUCCESS",
    AUTH_OTP_VERIFY_SUCCESS: "auth/AUTH_OTP_VERIFY_SUCCESS",
    AUTH_RESET: "auth/RESET",
    AUTH_SET_TOKENS: "auth/AUTH_SET_TOKENS",
    AUTH_RESET_TOKENS: "auth/AUTH_RESET_TOKENS",
    AUTH_REFRESH_TOKENS: "auth/AUTH_REFRESH_TOKENS",
    
    FETCH_USER_DETAILS: "user/FETCH_USER_DETAILS",
    USER_DETAILS_RESPONSE: "user/USER_DETAILS_RESPONSE",
    USER_UPDATE_RESPONSE: "user/USER_UPDATE_RESPONSE",
    UPDATE_USER_DETAILS: "user/UPDATE_USER_DETAILS",
    USER_LOADING: "user/USER_LOADING",
    USER_UPDATE_LOADING: "user/USER_UPDATE_LOADING",
    USER_SET_LOGGED_IN: "user/USER_SET_LOGGED_IN",
    RESET_USER_DETAILS: "user/RESET_USER_DETAILS",

    PAYMENT_SAVE: "payment/PAYMENT_SAVE",
    PAYMENT_LOADING: "payment/PAYMENT_LOADING",
    PAYMENT_SAVE_RESPONSE: "payment/PAYMENT_SAVE_RESPONSE",
    RESET_PAYMENT_DETAILS: "payment/RESET_PAYMENT_DETAILS",
}