import actions from '../types';

const initialState = {
    orgList: [],
    currentOrg: {
        name: "",
        email: "",
        phone: "",
        location: {}
    },
    isOrgLoading: false,
}

export default function organizationReducer(state = initialState, action) {
    switch  (action.type) {
        case actions.LOAD_ORG:
            return {
                ...state,
                currentOrg: {...action.currentOrg}
            };
        case actions.ORG_LOADING:
            return {
                ...state, isOrgLoading: action.payload
            };
        case actions.UPDATED_ORG_SUCCESS:
            return {
                ...state,
                isOrgUpdated: action.payload 
            }
        case actions.RESET_ORG:
            return {
                ...state,
                currentOrg: {}
            }
        default:
            return state;
    }
}