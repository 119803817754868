import actions from '../types';

const initialState = {
    reservationDetails: {},
    isReservationLoading: false,
}

export default function reservationsReducer(state = initialState, action) {
    switch  (action.type) {
        case actions.RESERVATION_LOADING:
            return {
                ...state, isReservationLoading: action.payload
            };
        case actions.LOAD_RESERVATION:
            console.log(`action LOAD_RESERVATION currentReservation ${action.currentReservation}`)
            return {
                ...state,
                currentReservation: {...action.currentReservation}
            };
        case actions.LOAD_RESERVATION_LIST:
            return {
                ...state,
                currentReservationList: {...action.currentReservationList}
            }
        case actions.RESET_RESERVATION:
            return {
                ...state,
                currentReservation: null
            }
        default:
            return state;
    }
}