import { combineReducers } from "redux";
import organizationReducer from "./organizationReducer";
import availableClassesReducer from "./availableClassesReducer";
import reservationsReducer from "./reservationsReducer";
import userReducer from "./userReducer";
import authReducer from "./authReducer";
import paymentReducer from "./paymentReducer";

export default history => combineReducers({
    organization: organizationReducer,
    availableClasses: availableClassesReducer,
    reservations: reservationsReducer,
    user: userReducer,
    auth: authReducer,
    payment: paymentReducer,
});