import actions from "../../store/types";
import { getAccessToken } from "./commonSaga";

import {put, call, all, takeEvery, select} from "redux-saga/effects";

import { getMyOrganization, getOrganization, updateOrganization } from "../services/organizationAPI";

function* GET_ORG(action) {
    try {
        const accessToken = yield select(getAccessToken);
        yield put({
            type: actions.ORG_LOADING,
            payload: true
        });
        const organization = yield call(getOrganization(accessToken, action.orgId));
        yield put({
            type: actions.LOAD_ORG,
            currentOrg: organization 
        })
        console.log(`GET_AVAILABLE_CLASSES: dispatching with org id ${organization.id}`)
        yield put({
            type: actions.GET_AVAILABLE_CLASSES,
            orgId: organization.id 
        })
    } catch(error) {
        console.log(`get org - error ${error.response.data.statusCode} ${error.response.data.message} ${error.response.data.statusCode === 401 && error.response.data.message === "Authorization token expired"}`);
        if(error.response.data.statusCode === 401 && error.response.data.message === "Authorization token expired") {
            yield put({
                type: actions.AUTH_REFRESH_TOKENS
            })
            yield put({
                type: actions.GET_ORG,
                orgId: action.orgId
            })
        }
        yield put({
            type: actions.LOAD_ORG,
            currentOrg: {"error": error.message}
        })
    } finally {
        yield put({
            type: actions.ORG_LOADING,
            payload: false
        })
    }
}

function* GET_MY_ORG(action) {
    try {
        const accessToken = yield select(getAccessToken);
        yield put({
            type: actions.ORG_LOADING,
            payload: true
        });
        const organization = yield call(getMyOrganization(accessToken));
        yield put({
            type: actions.LOAD_ORG,
            currentOrg: organization 
        })
        console.log(`GET_AVAILABLE_CLASSES: dispatching with org id ${organization.id}`)
        yield put({
            type: actions.GET_AVAILABLE_CLASSES,
            orgId: organization.id 
        })
    } catch(error) {
        if(error.response.data.statusCode === 401 && error.response.data.message === "Authorization token expired") {
            yield put({
                type: actions.AUTH_REFRESH_TOKENS
            })
            yield put({
                type: actions.GET_MY_ORG,
            })
        } else {
            yield put({
                type: actions.LOAD_ORG,
                currentOrg: {"error": error.message}
            })
        }
    } finally {
        yield put({
            type: actions.ORG_LOADING,
            payload: false
        })
    }
}

function* UPDATE_ORG(action) {
    try {
        const accessToken = yield select(getAccessToken);
        console.log('updating org ');
        console.log(action.payload);
        yield put({
            type: actions.ORG_LOADING,
            payload: true
        });
        const response = yield call(updateOrganization(accessToken, action.payload));
        console.log(response);
        if(response.status === 200) {
            yield put({
                type: actions.UPDATED_ORG_SUCCESS,
                payload: true
            })
            if(action.payload.id){
                yield put({
                    type: actions.GET_ORG,
                    orgId: action.payload.orgId 
                })
            }
        } else {
            yield put({
                type: actions.UPDATED_ORG_SUCCESS,
                payload: false
            })
            yield put({
                type: actions.LOAD_ORG,
                currentOrg: {"error": response.message}
            })
        }
    } catch(error) {
        console.log(error);
        yield put({
            type: actions.UPDATED_ORG_SUCCESS,
            payload: false
        })
        yield put({
            type: actions.LOAD_ORG,
            currentOrg: {"error": error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message}
        })
    } finally {
        yield put({
            type: actions.ORG_LOADING,
            payload: false
        })
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_ORG, GET_ORG),
        takeEvery(actions.GET_MY_ORG, GET_MY_ORG),
        takeEvery(actions.UPDATE_ORG, UPDATE_ORG)
    ])
}