import axios from "axios";
import { API_ENDPOINT } from "../../config";

const baseUrl = API_ENDPOINT;

export const getReservation = (reservationId) => async () => {
    return axios({
        method: "get",
        url: `${baseUrl}/reservations/${reservationId}`,
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}
export const listReservations = (queryParams) => async () => {
    return axios({
        method: "get",
        url: `${baseUrl}/reservations`,
        headers: {
            "Content-Type": "application/json"
        },
        params: queryParams,
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export const listOrders = (queryParams, accessToken) => async () => {
    return axios({
        method: "get",
        url: `${baseUrl}/orders`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        },
        params: queryParams,
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export const saveReservation = (payload) => async () => {
    console.log(`saveReservation - invoked with payload ${JSON.stringify(payload)}`)
    return axios({
        method: "post",
        url: `${baseUrl}/reservations`,
        headers: {
            "Content-Type": "application/json"
        },
        data: payload
    });
}