import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from './reducers';
import {thunk} from "redux-thunk";
import sagas from './sagas';
import { applyMiddleware, createStore} from "redux";
import { createBrowserHistory } from "history";
import createSagaMiddleware  from "redux-saga";
import { composeWithDevTools } from '@redux-devtools/extension';

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["user", "auth"],
}
const composeEnhancers = composeWithDevTools({
    trace: true, traceLimit: 25
})
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];
const persistedReducer = persistReducer(persistConfig, reducers(history));
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)));

const persistor = persistStore(store, null, () => {
    sagaMiddleware.run(sagas);
})
export {store, persistor, history}

