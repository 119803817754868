import actions from '../types';

const initialState = {
    loggedIn: false,
    userDetails: {
        firstName: "",
        lastName: "",
        phone: "",
        email: ""
    }
}

export default function userReducer(state = initialState, action) {
    switch  (action.type) {
        case actions.USER_LOADING:
            return {
                ...state, isUserDetailsLoading: action.payload
            };
        case actions.USER_DETAILS_RESPONSE:
            return {
                ...state, userDetails: action.payload
            }
        case actions.USER_UPDATE_RESPONSE:
            return {
                ...state, userUpdateResponse: action.payload
            }
        case actions.USER_SET_LOGGED_IN:
            return {
                ...state, loggedIn: action.payload
            }
        case actions.USER_UPDATE_LOADING:
            return {
                ...state, isUserProfileUpdating: action.payload
            }
        case actions.RESET_USER_DETAILS:
            return {
                ...state, userDetails: {}
            }
        default:
            return state;
    }

}