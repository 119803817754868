import axios from "axios";
import { API_ENDPOINT } from "../../config";

const baseUrl = API_ENDPOINT;

export const getOrganization = (accessToken, orgId) => async () => {
    return axios({
        method: "get",
        url: `${baseUrl}/organizations/${orgId}`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}
export const getMyOrganization = (accessToken) => async () => {
    return axios({
        method: "get",
        url: `${baseUrl}/myorganization`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export const updateOrganization = (accessToken, payload) => async () => {
    return axios({
        method: "post",
        url: `${baseUrl}/organizations`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        },
        data: payload
    })
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err));
}
export const getOrganizationList = () => async () => {
    return axios({
        method: "get",
        url: `${baseUrl}/organizations`,
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

