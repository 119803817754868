import axios from "axios";
import { API_ENDPOINT } from "../../config";

const baseUrl = `${API_ENDPOINT}/auth`;

export const otpRequest = (payload) => async () => {
    return axios({
        method: "post",
        url: `${baseUrl}/request-otp`,
        headers: {
            "Content-Type": "application/json"
        },
        data: payload
    })
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err));
}

export const otpVerify = (payload) => async () => {
    return axios({
        method: "post",
        url: `${baseUrl}/verify-otp`,
        headers: {
            "Content-Type": "application/json"
        },
        data: payload
    })
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err));
}

export const refreshTokens = (payload) => async () => {
    return axios({
        method: "post",
        url: `${baseUrl}/refresh`,
        headers: {
            "Content-Type": "application/json"
        },
        data: payload
    })
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err));
}


