import actions from '../types';

const initialState = {
    paymentIntent: {}
}

export default function paymentReducer(state = initialState, action) {
    switch  (action.type) {
        case actions.PAYMENT_LOADING:
            return {
                ...state, isPaymentLoading: action.payload
            };
        case actions.PAYMENT_SAVE_RESPONSE:
            console.log(`action PAYMENT_SAVE_RESPONSE paymentIntentResponse ${action.payload}`)
            return {
                ...state,
                paymentResponse: {...action.payload}
            };
        case actions.RESET_PAYMENT_DETAILS:
            return {
                ...state,
                paymentResponse: null,
                isPaymentLoading: false
            }
        default:
            return state;
    }
}