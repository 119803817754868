import axios from "axios";
import { API_ENDPOINT } from "../../config";

const baseUrl = `${API_ENDPOINT}/payment`;

export const savePayment = (payload) => async () => {
    console.log(`savePayment - invoked with payload ${JSON.stringify(payload)}`)
    return axios({
        method: "post",
        url: `${baseUrl}/save`,
        headers: {
            "Content-Type": "application/json"
        },
        data: payload
    });
}