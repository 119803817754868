import React from "react";
import { Box, List, ListItem, ListItemButton, ListItemText, Divider } from "@mui/material";
import './NavBarLoggedIn.scss'
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux";

const NavBarLoggedIn = () => {
    const navigate = useNavigate();
    const handleOnClick = (path) => {
        navigate(path)
    };
    const handleOnClickProfile = () => {
        navigate('/uprofile')
    }
    const handleOnClickAccount = () => {
        navigate('/account')
    }
    const handleOnClickTerms = () => {
        navigate('/terms')
    };
    const handleLogin = () => {
        navigate('/login')
    }
    const loggedIn = useSelector((state) => state.user.loggedIn)
    const isBusinessProfile = useSelector((state) => state.user.userDetails && state.user.userDetails.isBusinessProfile)
    return (
        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: "10px",
            p: 1,
            }}>
            {!loggedIn && <button className="login-button" onClick={handleLogin}>
                Login
            </button> 
            }
            <List>
                {loggedIn && <ListItem key={"profile"} disablePadding>
                    <ListItemButton>
                        <ListItemText onClick={handleOnClickProfile} primary={"View Profile"} />
                    </ListItemButton>
                </ListItem>}
                {loggedIn && <ListItem key={"account"} disablePadding>
                    <ListItemButton>
                        <ListItemText onClick={handleOnClickAccount} primary={"View Account"} />
                    </ListItemButton>
                </ListItem>}
                {!isBusinessProfile && <ListItem key={"create-business-account"} disablePadding>
                    <ListItemButton>
                        <ListItemText onClick={(e) => {handleOnClick('/setupbusiness')}} primary={"Create a business account"} />
                    </ListItemButton>
                </ListItem>}
                {isBusinessProfile && <ListItem key={"management"} disablePadding>
                    <ListItemButton>
                        <ListItemText onClick={(e) => {handleOnClick('/management')}} primary={"Management Portal"} />
                    </ListItemButton>
                </ListItem>}
                <ListItem key={"view-terms-and-conditions"} disablePadding>
                    <ListItemButton>
                        <ListItemText onClick={handleOnClickTerms} primary={"View Terms & Conditions"} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
        </Box>
    )
}
export default NavBarLoggedIn;