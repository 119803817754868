import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import { store } from "./store";
import App from './App';

if (process.env.NODE_ENV !== "development")
    console.log = () => {};

ReactDOM.render(
  <Provider store={store}>
    <App></App>
  </Provider>, document.getElementById("root"),
);