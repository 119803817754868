import React, {lazy} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom"
import ProtectRoute from "./containers/ProtectedRoute";
import NavDrawer from "./containers/NavDrawer";

export const routes = [
    {
        id: '1',
        key: "home",
        path: "/",
        label: "Home",
        component: lazy(() => import("./view/Home")),
        exact: true,
        defaultAuthenticated: true,
        isBusinessRoute: false
    },
    {
        id: '2',
        key: "about-us",
        path: "/about-us",
        label: "About Us",
        component: lazy(() => import("./view/AboutUs")),
        exact: true,
        defaultAuthenticated: true,
        isBusinessRoute: false
    },
    {
        id: '3',
        key: "profile",
        path: "/profile/:orgID",
        label: "Profile",
        component: lazy(() => import("./view/Profile")),
        exact: true,
        isBusinessRoute: false,
        defaultAuthenticated: true
    },
    {
        id: '4',
        key: "setupbusiness",
        path: "/setupbusiness",
        label: "Business Setup",
        component: lazy(() => import("./view/OrganizationSetup")),
        exact: true,
        defaultAuthenticated: false,
        isBusinessRoute: true
    },
    {
        id: '5',
        key: "management",
        path: "/management",
        label: "Management",
        component: lazy(() => import("./view/Management")),
        exact: true,
        defaultAuthenticated: false,
        isBusinessRoute: true
    },
    {
        id: '6',
        key: "terms",
        path: "/terms",
        label: "Terms",
        component: lazy(() => import("./view/Terms")),
        exact: true,
        defaultAuthenticated: true,
        isBusinessRoute: false
    },
    {
        id: '7',
        key: "login",
        path: "/login",
        label: "Login",
        component: lazy(() => import("./view/Login")),
        exact: true,
        defaultAuthenticated: true,
        isBusinessRoute: false
    },
    {
        id: '8',
        key: "userProfile",
        path: "/uprofile",
        label: "userProfile",
        component: lazy(() => import("./view/UserProfile")),
        exact: true,
        defaultAuthenticated: false,
        isBusinessRoute: false
    },
    {
        id: '9',
        key: "event",
        path: "/event/:classId",
        label: "Event",
        component: lazy(() => import("./view/ClassInfo")),
        exact: true,
        isBusinessRoute: false,
        defaultAuthenticated: true
    },
    {
        id: '10',
        key: "account",
        path: "/account",
        label: "Account",
        component: lazy(() => import("./view/AccountView")),
        exact: true,
        isBusinessRoute: false,
        defaultAuthenticated: false
    },
];

class Router extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    {routes.map(route => (
                       <Route 
                        path={route.path}
                        element={
                        <ProtectRoute {...route} key={route.path}>
                            <NavDrawer></NavDrawer>
                            <route.component/>
                        </ProtectRoute>
                        }>
                       </Route>
                    ))}
                </Routes>
            </BrowserRouter>
        )
    }
}

export default Router;