import React from "react";
import { Navigate } from "react-router";
import { useSelector } from "react-redux";


const ProtectRoute = (props) => {
    // const urlPath = useSelector(state => state.router.location.pathname);
    useSelector((state) => console.log(state));
    const loggedIn = useSelector((state) => state.user.loggedIn)
    const { children, defaultAuthenticated, path } = props;
    console.log(`protected route - ${path} - logged in - ${loggedIn} - defaultAuthenticated - ${defaultAuthenticated}`)
    const isAuthenticated = defaultAuthenticated || loggedIn;
    return isAuthenticated ? children : <Navigate to="/login"></Navigate>
};

export default ProtectRoute;
