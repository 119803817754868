import actions from "../../store/types";

export const otpRequest = email => async(dispatch, getState) => {
    console.log("dispatching otp request");
    dispatch({
        type: actions.AUTH_OTP_REQUEST,
        payload: {email}
    })
};
export const otpVerify = payload => async(dispatch, getState) => {
    console.log("dispatching otp verify");
    dispatch({
        type: actions.AUTH_OTP_VERIFY,
        payload: payload
    })
};
export const authReset = () => async(dispatch, getState) => {
    console.log("dispatching resetting auth");
    dispatch({
        type: actions.AUTH_RESET
    })
};

export const resetTokens = () => async(dispatch, getState) => {
    console.log("dispatching resetting tokens");
    dispatch({
        type: actions.AUTH_RESET_TOKENS
    })
    dispatch({
        type: actions.USER_SET_LOGGED_IN,
        payload: false
    })
    dispatch({
        type: actions.RESET_USER_DETAILS,
    })
};