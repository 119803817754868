import axios from "axios";
import { API_ENDPOINT } from "../../config";

const baseUrl = `${API_ENDPOINT}/user`;

export const fetchUserDetails = (accessToken) => async () => {
    return axios({
        method: "get",
        url: `${baseUrl}/details`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        },
    })
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err));
}

export const updateUserInfo = (accessToken, payload) => async () => {
    return axios({
        method: "post",
        url: `${baseUrl}/update`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        },
        data: payload
    })
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err));
}