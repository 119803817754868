import React from "react";
import "./Spinner.scss";

const Spinner = () => {
    return (
        <div className="spinner">
            <svg
            className="spinner__svg"
            version="1.1"
            id="loader-1"
            x="0px"
            y="0px"
            viewBox="0 0 50 50"
            xmlSpace="preserve"
            >
                <path
                fill="rgb(16,127,98)"
                >
                    <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="0.7s"
                    repeatCount="indefinite"
                    ></animateTransform>
                </path>
            </svg>
        </div>
    )
};

export default Spinner;