import React, { Component, Suspense } from 'react';
import Spinner from './components/Spinner';
import Router from './router';
import { StrictMode } from 'react';
import { Mixpanel } from './libraries/Mixpanel';

Mixpanel.init();
class App extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner/>}>
        <StrictMode>
            <Router></Router>
        </StrictMode>
      </Suspense>
    )
  }
}

export default App;
