import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const desiArtHubTheme = responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#000000'
      }
    },
    components: {
        MuiTypography: {
            confirmationHeader: {
                fontSize: '1rem'
            }
        },
        MuiButton: {
          styleOverrides: {
            root: {
              backgroundColor: 'black',
              width: '-webkit-fill-available',
              textAlign: 'center',
              borderRadius: '4px',
              color: 'white',
              '&:hover': {
                backgroundColor: 'black'
              }
            }
          }
        },
        MuiInputBase: {
          styleOverrides: {
              input: {
                fontFamily: 'Poppins',
                fontSize: '1rem'
              }
            }
          },
        MuiPickersDay: {
          styleOverrides: {
            root: {
              color: '#000000',
              fontSize: '1rem',
                "&:hover": {
                    backgroundColor: "#D3D3D3",
                },
                disabled: {
                    color: '#0E0E0E20'
                },
                selected: {
                    backgroundColor: '#000000',
                    "&:hover": {
                        backgroundColor: "#000000",
                    }
                  },
            },
          },
        },
      },
    
    typography: {
        fontFamily: 'Poppins'
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
          mobile: 0,
          tablet: 640,
          laptop: 1024,
          desktop: 1200,
        },
      },
}))
