import actions from '../types';

const initialState = {
    availableClasses: [],
    isClassesLoading: false,
}

export default function availableClassesReducer(state = initialState, action) {
    switch  (action.type) {
        case actions.LOAD_AVAILABLE_CLASSES:
            return {
                ...state,
                availableClasses: action.availableClasses
            };
        case actions.AVAILABLE_CLASSES_LOADING:
            return {
                ...state, isClassesLoading: action.payload
            };
        case actions.SET_CURRENT_AVAILABLE_CLASSES:
            return {
                ...state, currentAvailableClass: action.currentAvailableClass
            }
        case actions.UPDATED_AVAILABLE_SUCCESS:
            return {
                ...state,
                isAvailableClassUpdated: action.payload 
            }
        default:
            return state;
    }
}