import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from '../config'

// const mixpanel_active = process.env.NODE_ENV === 'production';
const mixpanel_active = true;

let actions = {
    init: () => {
        mixpanel.init(MIXPANEL_TOKEN);
    },
    identify: (id) => {
      if (mixpanel_active) mixpanel.identify(id);
    },
    alias: (id) => {
      if (mixpanel_active) mixpanel.alias(id);
    },
    track: (name, props) => {
      if (mixpanel_active) mixpanel.track(name, props);
    },
    people: {
      set: (props) => {
        if (mixpanel_active) mixpanel.people.set(props);
      },
    },
  };
  export let Mixpanel = actions;